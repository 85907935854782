import globalStore from '@/services/global.state';
import React from 'react';

const useInfiniteScroll = (id: string) => {
  const onEndReachedCalledDuringMomentum = React.useRef(true);

  React.useEffect(() => {
    const handleScroll = () => {
      // console.log(e.target);
      // const scrollbox = e.target;
      // const isBottom =
      //   scrollbox.scrollHeight -
      //     scrollbox.offsetHeight -
      //     scrollbox.scrollTop ===
      //   20;
      // if (lockScroll.current) {
      //   return;
      // }
      // if (isBottom) {
      //   lockScroll.current = true;
      //   console.log('???');
      //   onLoadMore();
      // }
      onEndReachedCalledDuringMomentum.current = false;
    };

    if (globalStore.isWeb) {
      const scrollbox = document.getElementById(id);
      scrollbox?.addEventListener('scroll', handleScroll);

      return () => {
        scrollbox?.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    onEndReachedCalledDuringMomentum,
  };
};

export default useInfiniteScroll;
