/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useRef, useState} from 'react';
import {TextInput, View} from 'react-native';
import {
  topone,
  toptwo,
  content,
  bottom,
  CardOuterBg,
  CardTopRightImg,
  invitationColors,
  invitationApplyColor,
} from './invitation.variables';
import ImageBackgroundBasic from './ImageBackgroundBasic';
import Text from '@basicComponents/text';

import LinearGradient from '@basicComponents/linear-gradient';
import {BasicObject, SafeAny} from '@/types';

import InvitationApplyModal from './invitation-apply-modal';
import {
  basicColor,
  borderRadius,
  flex,
  fontSize,
  margin,
  padding,
  position,
} from '@/components/style';
import LazyImage from '@/components/basic/image';
import {InvitationCode, PhoneIcon, SaveIcon, SignGiftBg} from './svg.variables';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {sendCode, userLogin} from '../login/login.service';
import globalStore from '@/services/global.state';
import i18n from '@/i18n';
import baseVariable from '@/style/base.variable';
import {useResponsiveDimensions} from '@/utils';
const outline: SafeAny = {
  outline: 0,
};
const InvitationApply = (props: SafeAny) => {
  const [userPhone, setUserPhone] = useState('');
  const [OTPCode, setOTPCode] = useState('');
  const OTPTimeRef = useRef(59);
  const InvitationApplyModalRef: SafeAny = useRef(null);
  const [OTPTime, setOTPTime] = useState(59);
  const [hasOTP, setOTP] = useState(false);
  const [OTPLoading, setOTPLoading] = React.useState(false);
  const {route} = props;
  const [invitaCode, setInvitaCode] = React.useState(
    (route.params as BasicObject)?.code,
  );
  const {width: screenWidth} = useResponsiveDimensions();
  const setHasOTP = () => {
    setOTP(true);
    OTPTimeRef.current = 59;
    setOTPTime(OTPTimeRef.current);
  };
  useEffect(() => {
    const timer = setInterval(function () {
      if (hasOTP && OTPTimeRef.current > 1) {
        setTimeout(() => {
          OTPTimeRef.current = OTPTimeRef.current - 1;
          setOTPTime(OTPTimeRef.current);
        }, 1000);
      } else {
        clearInterval(timer);
        setOTP(false);
      }
    }, 1000);
    return () => {
      timer && clearInterval(timer);
    };
  }, [hasOTP]);
  return (
    <View style={[{height: '100%'}]}>
      <ImageBackgroundBasic Img={topone} />
      <ImageBackgroundBasic Img={toptwo} />
      <ImageBackgroundBasic Img={content} />
      <ImageBackgroundBasic Img={bottom} />
      <View
        style={[
          position.abs,
          {paddingBottom: 24, width: '100%', bottom: 24},
          padding.lrl,
          {width: screenWidth},
        ]}>
        <LazyImage
          imageUrl={CardOuterBg}
          occupancy="#0000"
          width={'100%'}
          height={372}
        />
        <View
          style={[
            padding.s,
            position.abs,
            flex.between,
            flex.flex,
            {width: screenWidth - 24},
          ]}>
          <View style={[position.abs, {top: 8}]}>
            <SignGiftBg
              width={screenWidth - 48}
              height={((screenWidth - 48) / 335) * 67}
              fill={invitationColors.signGiftBgColor}
            />
            <View style={[position.abs, {top: 9, left: 18, width: 200}]}>
              <Text fontSize={20} color={'#FFF'} fontFamily="fontInterBold">
                SIGN UP AND GET GIFTS
              </Text>
            </View>
          </View>
          <View
            style={[
              padding.xxl,
              {marginTop: (route.params as BasicObject)?.code ? 105 : 75},
            ]}>
            <View
              style={[
                {
                  height: 48,
                  borderWidth: 1,
                  borderColor: invitationApplyColor.borderColor,
                  backgroundColor: invitationApplyColor.backgroundColor,
                },
                padding.lrl,
                borderRadius.m,
                flex.row,
                flex.centerByCol,
              ]}>
              <PhoneIcon width={24} height={24} />
              <TextInput
                placeholder="Phone number"
                style={[
                  {
                    height: 24,
                    marginLeft: 16,
                    borderWidth: 0,
                    borderColor: 'none',
                  },
                  flex.flex1,
                  outline,
                ]}
                value={userPhone}
                maxLength={10}
                placeholderTextColor={baseVariable.fontColor.secAccent}
                onChange={(e: SafeAny) => setUserPhone(e.target.value)}
              />
            </View>
            <View
              style={[
                {
                  height: 48,
                  borderWidth: 1,
                  borderColor: invitationApplyColor.borderColor,
                  backgroundColor: invitationApplyColor.backgroundColor,
                },
                padding.lrl,
                borderRadius.m,
                flex.row,
                flex.centerByCol,
                margin.topl,
              ]}>
              <SaveIcon width={24} height={24} />
              <TextInput
                placeholder="OTP"
                underlineColorAndroid={'transparent'}
                style={[
                  {
                    height: 24,
                    marginLeft: 16,
                    borderWidth: 0,
                    borderColor: 'none',
                    fontSize: 15,
                  },
                  flex.flex1,
                  outline,
                ]}
                value={OTPCode}
                maxLength={6}
                placeholderTextColor={baseVariable.fontColor.secAccent}
                onChangeText={value => {
                  if (value) {
                    if (/^[0-9]+$/.test(value)) {
                      setOTPCode(value);
                    } else {
                      setOTPCode(OTPCode || '');
                    }
                  } else {
                    setOTPCode('');
                  }
                }}
              />
              {hasOTP ? (
                <Text
                  fontSize={fontSize.s}
                  accent
                  style={{
                    lineHeight: fontSize.s,
                  }}
                  textAlign="center"
                  blod>
                  {OTPTime}s
                </Text>
              ) : (
                <NativeTouchableOpacity
                  onPress={() => {
                    if (!userPhone) {
                      return;
                    }
                    if (OTPLoading) {
                      return;
                    }
                    setOTPLoading(true);
                    sendCode(userPhone)
                      .then(() => {
                        setHasOTP();
                        globalStore.globalTotal.next({
                          type: 'success',
                          message: i18n.t('tip.success'),
                        });
                      })
                      .finally(() => setOTPLoading(false));
                  }}>
                  <Text
                    color={basicColor.primary}
                    fontSize={15}
                    fontWeight="bold">
                    Send
                  </Text>
                </NativeTouchableOpacity>
              )}
            </View>
            {!(route.params as BasicObject)?.code && (
              <View
                style={[
                  {
                    height: 48,
                    borderWidth: 1,
                    borderColor: invitationApplyColor.borderColor,
                    backgroundColor: invitationApplyColor.backgroundColor,
                  },
                  padding.lrl,
                  borderRadius.m,
                  flex.row,
                  flex.centerByCol,
                  margin.topl,
                ]}>
                <InvitationCode width={24} height={24} />
                <TextInput
                  placeholder="Enter invite code(Optional)"
                  style={[
                    {
                      height: 24,
                      marginLeft: 16,
                      borderWidth: 0,
                      borderColor: 'none',
                    },
                    flex.flex1,
                    outline,
                  ]}
                  value={invitaCode}
                  maxLength={10}
                  placeholderTextColor={baseVariable.fontColor.secAccent}
                  onChange={(e: SafeAny) => setInvitaCode(e.target.value)}
                />
              </View>
            )}
          </View>
          <View style={[position.abs, {right: -8, top: 0}]}>
            <LazyImage
              imageUrl={CardTopRightImg}
              occupancy="#0000"
              width={110}
              height={42}
            />
          </View>
        </View>
        <NativeTouchableOpacity
          style={[padding.lrl, {width: '100%'}]}
          onPress={() => {
            if (OTPLoading) {
              return;
            }
            if (!userPhone || !OTPCode) {
              return;
            }
            globalStore.globalLoading.next(true);
            userLogin(userPhone, OTPCode, invitaCode)
              .then(() => {
                InvitationApplyModalRef.current.showModal();
              })
              .finally(() => globalStore.globalLoading.next(false));
          }}>
          <View
            style={[
              position.abs,
              {bottom: 33},
              flex.flex1,
              flex.center,
              {
                backgroundColor: basicColor.primary,
                width: screenWidth - 48,
                height: 48,
                borderRadius: 30,
              },
            ]}>
            <LinearGradient
              style={[{width: '100%', height: 48}, flex.center]}
              start={{x: 0, y: 0}}
              end={{x: 1, y: 1}}
              colors={basicColor.primaryLinearGradient}>
              <View>
                <Text fontSize={16} color={'#fff'} fontFamily="fontInterBold">
                  Register and download
                </Text>
              </View>
            </LinearGradient>
          </View>
        </NativeTouchableOpacity>
      </View>
      <InvitationApplyModal ref={InvitationApplyModalRef} />
    </View>
  );
};

export default InvitationApply;
